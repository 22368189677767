<template>
<div class="card">
    <div class="card-body">
        <div class="">
            <h5 class="border-bottom pb-2">Device details</h5>
            <div class="mb-4 d-sm-flex align-items-center justify-content-start">
                <div class="me-sm-3">
                    <img v-if="item.device.image" style="width:3rem;" :src="absoluteUrl(item.device.image)" />
                    <div v-else class="avatar img-thumbnail me-2 flex-shrink-0">
                        <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                            {{item.device.model.charAt(0).toUpperCase()}}
                        </div>
                    </div>
                </div> 
                <div class="">
                    <p class="mb-1"><strong>Model:</strong> {{item.device.model}}</p>
                    <div class="">
                        <span v-if="item.device.brand" class="me-3"> <strong>Make: </strong> {{item.device.brand.title}}</span>
                        <span v-if="item.device.category"> <strong>Type: </strong> {{item.device.category.title}}</span>
                    </div>
                    <p v-if="item.device_condition" class="mb-0"><strong>Device Condition:</strong> <span class="text-uppercase"> {{item.device_condition}} </span></p>
                </div>
            </div>
            <div class="row g-3">
                <div v-if="item.serial_number" class="col-sm-6"> 
                    <span>Serial Number</span>
                    <h6> {{item.serial_number}} </h6>
                </div>
                <div v-if="item.imei_number" class="col-sm-6"> 
                    <span>Imei Number</span>
                    <h6> {{item.imei_number}} </h6>
                </div>
            </div>
        </div>            
    </div>
</div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            required: true
        }
    },
    computed:{
     
    },    
}
</script>

<style>

</style>