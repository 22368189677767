<template>
  <div>
    <div class="table-responsive">
        <table class="table table-nowrap mb-0">
            <thead class="table-light">
                <tr>
                <th scope="col">Date</th>
                <th scope="col">Amount</th>
                <th scope="col">Balance</th>
                <th scope="col">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, biIndex) in items" :key="biIndex">
                <td>{{$filters.date(item.date, 1) }}</td>
                <td>${{$filters.money_format(item.amount)}}</td>
                <td>${{$filters.money_format(item.balance)}}</td>
                <td><span v-html="statusBadge(item.status)"></span></td>
                </tr>
            </tbody>
        </table>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        items:{
            default:()=>([])
        }
    }
}
</script>

<style>

</style>