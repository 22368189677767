<template>
<div class="card card-height-100">
    <div class="card-body">
        <div class="">
            <h5 class="border-bottom pb-2">Bank Account</h5>
            <div class="row g-3">
                <div class="col-12 col-md-6">
                    <label class="form-label fw-bold">Account holder name</label>
                    <div>{{item.account_holder_name}}</div>
                </div>
                <div class="col-12 col-md-6">
                    <label class="form-label fw-bold">Account number</label>
                    <div>{{item.account_number}}</div>
                </div>
                <div class="col-12 col-md-6">
                    <label class="form-label fw-bold">Bank name</label>
                    <div>{{item.bank_name}}</div>
                </div>
                <div class="col-12 col-md-6">
                    <label class="form-label fw-bold">Routing number</label>
                    <div>{{item.routing_number}}</div>
                </div>
            </div>
        </div>            
    </div>
</div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            required: true
        }
    },
    computed:{
     
    },    
}
</script>

<style>

</style>