
<template>
<div class="pb-4">
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Lease view</h5>
      <div v-if="contract">
        <div class="card mb-5">
          <div class="card-body">
            <div class="row gy-3 justify-content-between align-items-start">
              <div class="col-10">
                <div class="row g-2  gx-sm-5">
                  <div class="col-12"> 
                    <div class="friendly-id mb-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard">
                      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                      <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                      </svg>
                      {{contract.friendly_id}}
                    </div>
                  </div> 
                </div>
              </div>
              <div class="col-2 text-sm-end">
                <div class="dropdown no-arrow">
                  <button class="btn btn-sm btn-light dropdown-toggle" type="button" role="button" 
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a @click.prevent="popupModalUpdate = true" class="dropdown-item" href="javascript:void(0)" >
                      Update status </a>
                    </li>
                    <li>
                      <router-link :to="`/users/${contract.partner_id}`" class="dropdown-item">
                      View partner </router-link>
                    </li>
                    <li>
                      <router-link :to="`/users/${contract.customer_id}`" class="dropdown-item">
                      View customer </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row g-2  gx-sm-5">
              <div class="col-12"> 
                <span class="me-2">Status:</span>
                <span v-html="statusBadge(contract.status)"> </span>
              </div>
              <div v-if="contract.payment_method" class="col-12"> 
                <payment-method-card :item="contract.payment_method"/>
              </div>
              <div class="col-sm-6"> 
                <span class="me-2">First name:</span>
                <strong> {{contract.customer.first_name}} </strong>
              </div>
              <div class="col-sm-6"> 
                <span class="me-2">Last name:</span>
                <strong> {{contract.customer.last_name}} </strong>
              </div>
              <div class="col-sm-6"> 
                <span class="me-2">Phone:</span>
                <strong> {{contract.customer.mobile}} </strong>
              </div>
              <div class="col-sm-6"> 
                <span class="me-2">Income source:</span>
                <strong class="text-capitalize"> {{contract.income_source.replace(/_/g, ' ')}} </strong>
              </div>
              <div class="col-sm-6"> 
                <span class="me-2">Income payment mode:</span>
                <strong class="text-capitalize"> {{contract.income_payment_mode.replace(/_/g, ' ')}} </strong>
              </div>
              <div class="col-md-6"> 
                <span class="me-2">Monthly Net</span>
                <strong class="text-capitalize"> ${{$filters.money_format(contract.monthly_net)}} </strong>
              </div>
              <div class="col-sm-6"> 
                <span class="me-2">Email:</span>
                <strong> {{contract.customer.email}} </strong>
              </div>
              <div class="col-sm-6"> 
                <span class="me-2">Date of Birth:</span>
                <strong> {{$filters.date(contract.customer.dob)}} </strong>
              </div>
              <div v-if="contract.identity" class="col-12"> 
                <div class="row g-2  gx-sm-5">
                  <div class="col-sm-6"> 
                    <span class="me-2">SSN:</span>
                    <strong> {{contract.identity.ssn}} </strong>
                  </div>
                  <div class="col-sm-6"> 
                    <span class="me-2">Language:</span>
                    <strong class="text-capitalize"> {{contract.identity.language}} </strong>
                  </div>
                  <div class="col-sm-6"> 
                    <span class="me-2">ID type:</span>
                    <strong class="text-capitalize"> {{contract.identity.id_type.replace(/_/g, ' ')}} </strong>
                  </div>
                  <div class="col-sm-6"> 
                    <span class="me-2">ID Number:</span>
                    <strong> {{contract.identity.id_number}} </strong>
                  </div>
                  <div class="col-sm-6"> 
                    <span class="me-2">ID state:</span>
                    <strong> {{contract.identity.id_state}} </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row gy-2">
          <div v-if="contract.address" class="col-lg-6">
            <address-card :item="contract.address" />
          </div>
          <div v-if="contract.bank_account" class="col-lg-6">
            <bank-account-card :item="contract.bank_account" />
          </div>
          <div v-if="contract.contract_item" class="col-lg-6">
            <contract-item-card :item="contract.contract_item" />
          </div>
        </div>
        <div v-if="contract.schedule"  class="">
          <h5 class="mb-3">Schedule details</h5>
          <div class="card mb-5">
            <div class="card-body">
              <div class="row g-2  gx-sm-5">
                <div class="col-md-6"> 
                  <span class="me-2">Frequency:</span>
                  <strong class="text-capitalize"> {{contract.schedule.frequency.replace(/[_-]/g, ' ')}} </strong>
                </div>
                <div class="col-md-6"> 
                  <span class="me-2">Description:</span>
                  <strong> {{contract.schedule.description}} </strong>
                </div>
                <div class="col-md-6"> 
                  <span class="me-2">Lease term:</span>
                  <strong> {{contract.schedule.period_in_months}} months </strong>
                </div>
                <div class="col-md-6"> 
                  <span class="me-2">Number of renewals:</span>
                  <strong> {{contract.schedule.number_of_renewals}}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <h5 class="mb-3">Lease billings</h5>
        </div>
        <div class="card mb-5">
          <div class="card-body">
            <div class="row mb-4 g-2  gx-sm-5">
              <div class="col-md-6 col-lg-4"> 
                <span class="me-2">Initial Payment:</span>
                <strong class="text-capitalize"> ${{$filters.money_format(contract.initial_payment)}} </strong>
              </div>
              <div class="col-md-6 col-lg-4"> 
                <span class="me-2">Lease amount:</span>
                <strong class="text-capitalize"> ${{$filters.money_format(contract.leased_amount)}} </strong>
              </div>
              <div class="col-md-6 col-lg-4"> 
                <span class="me-2">Funding amount:</span>
                <strong class="text-capitalize"> ${{$filters.money_format(contract.funded_amount)}} </strong>
              </div>
              <div class="col-md-6 col-lg-4"> 
                <span class="me-2">Funding option:</span>
                <strong class="text-capitalize"> {{contract.funding_option}}</strong>
              </div>
              <div class="col-md-6 col-lg-4"> 
                <span class="me-2">Pricing factor:</span>
                <strong> {{contract.pricing_factor}}</strong>
              </div>
              <div class="col-md-6 col-lg-4"> 
                <span class="me-2">Renewal amount:</span>
                <strong> ${{ $filters.money_format(contract.renewal_amount)}}</strong>
              </div>
              <div class="col-md-6 col-lg-4"> 
                <span class="me-2">Total amount:</span>
                <strong> ${{ $filters.money_format(contract.total_amount)}}</strong>
              </div>
              <div class="col-md-6 col-lg-4"> 
                <span class="me-2">Cost of contract:</span>
                <strong> ${{ $filters.money_format(contract.cost_of_contract)}}</strong>
              </div>
            </div>
            <div v-if="contract.bills.length" class="">
              <lease-bill-table :items="contract.bills" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else  />
  <b-modal hide-footer v-model="popupModalUpdate" title="Update status">
    <lease-form v-if="popupModalUpdate" @closeMe="initializeAll()"
      :editItem="contract" @updateItem="updateItem($event)"/>
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import LeaseForm from "@/components/forms/LeaseForm.vue"
import LeaseBillTable from '@/components/tables/LeaseBillTable.vue'
import PaymentMethodCard from '@/components/cards/PaymentMethodCard.vue'
import AddressCard from '@/components/cards/AddressCard.vue'
import BankAccountCard from '@/components/cards/BankAccountCard.vue'
import ContractItemCard from '@/components/cards/ContractItemCard.vue'

export default {
  components: { 
    IsLoading,
    LeaseForm,
    LeaseBillTable,
    PaymentMethodCard,
    AddressCard,
    BankAccountCard,
    ContractItemCard
  },
  data(){
    return {
      isLoading: true,
      popupModalUpdate: false,
      contract: null
    }
  },
  watch: {
    '$route.params.leaseId': function (id){
      if(id) this.fetchItem()
    },
  },
  methods:{
    updateItem(item){
      Object.assign(this.contract, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/contracts/${this.$route.params.leaseId}/show`)
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.contract = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    initializeAll(){
      this.popupModalUpdate = false;
    },
  },
  created(){
    this.fetchItem()
  }
}

</script>

<style lang="scss">

</style>
